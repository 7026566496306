<template>
  <div>
    <Loading :loading="loading" />

    <section class="section has-background-white-ter">
      <div class="container">
        <div class="box">
          <div class="columns is-vcentered" v-if="formRequest">
            <div class="column is-4">
              <span class="is-size-7 has-text-weight-bold">Form</span>
              <span class="is-size-1 montserrat">W-8BEN</span>
            </div>
            <div class="column is-4 has-text-centered">
              <h3 class="title is-7 mb-0">Requested by</h3>
              <p>
                <span v-if="formRequest.sender.businessName">
                  {{ formRequest.sender.businessName }}<br />
                </span>
                <span v-else-if="formRequest.sender.name">
                  {{ formRequest.sender.name }}<br />
                </span>
                <a v-else :href="`mailto:${formRequest.sender.email}`">
                  {{ formRequest.sender.email }}
                </a>
              </p>
            </div>
            <div class="column is-4 has-text-right">
              <img
                v-if="formRequest.sender.logo"
                :src="images + formRequest.sender.logo"
                alt="requester-logo"
                style="max-width: 200px; max-height: 100px; margin: 5px"
              />
            </div>
          </div>
          <div class="columns is-vcentered" v-else>
            <div class="column is-6">
              <span class="is-size-7 has-text-weight-bold">Form</span>
              <span class="is-size-1 montserrat">W-8BEN</span>
            </div>
            <div class="column is-6 has-text-right"></div>
          </div>
          <br />

          <div class="tabs is-small">
            <ul>
              <li :class="{ 'is-active': step == 1 }">
                <a>1. Name &amp; Address</a>
              </li>
              <li :class="{ 'is-active': step == 2 }">
                <a>2. Tax ID Info</a>
              </li>
              <li :class="{ 'is-active': step == 3 }">
                <a>3. Treaty Benefits</a>
              </li>
              <li :class="{ 'is-active': step == 4 }">
                <a>4. Signature</a>
              </li>
              <li :class="{ 'is-active': step == 5 }">
                <a>5. Send Form</a>
              </li>
            </ul>
          </div>

          <div v-show="step == 10">
            <div class="notification is-warning p-6">
              This form request has already been fulfilled. If you would like to send a
              new form, you may start with a blank form
              <router-link :to="{ name: 'FormW8ben', query: null }">here</router-link>.
            </div>
          </div>

          <div v-show="step == 1">
            <!-- header section -->

            <!-- <div class="columns">
          <div class="column is-3">
            <h2 class="title is-size-4">Form W-8BEN</h2> 
          </div>
          <div class="column is-6 has-text-centered">
            Certificate of Foreign Status of Beneficial Owner for United
            States Tax Withholding and Reporting (Individuals)
          </div>
          <div class="column is-3 has-text-right">
            OMB No. 1545-1621
          </div>
        </div> -->

            <!-- instructions section -->

            <div class="content">
              <!-- <h4 class="title is-4">Form Requested by: Alan Hettinger</h4> -->
              <!-- <div> -->

              <!-- <h4 class="title is-5">Before we get started, don't use this form if:</h4> -->

              <div class="notification is-link is-light">
                <!-- <h6 class="title is-6 mb-1" v-if="formRequest">
                  This form was requested by:
                </h6>
                <p v-if="formRequest">
                  <span v-if="formRequest.sender.name">
                    {{ formRequest.sender.name }}<br />
                  </span>
                  <span v-if="formRequest.sender.businessName">
                    {{ formRequest.sender.businessName }}<br />
                  </span>
                  <a :href="`mailto:${formRequest.sender.email}`">
                    {{ formRequest.sender.email }}
                  </a>
                </p> -->

                <p>
                  <a @click="toggleShowInstructions()" style="text-decoration: none">
                    Is <b>W-8BEN</b> the right form for you?
                    <span class="icon has-text-right">
                      <i v-if="!showInstructions" class="fas fa-angle-down"></i>
                      <i v-else class="fas fa-angle-up"></i>
                    </span>
                  </a>
                </p>
                <div v-if="showInstructions">
                  <p>Don't use this form if:</p>
                  <ul>
                    <li>
                      You are a U.S. citizen or a resident alien (use
                      <router-link
                        :to="{ name: 'FormW9', query: { token: $route.query.token } }"
                      >
                        W-9 </router-link
                      >)
                    </li>
                    <li>
                      You're not an individual (use
                      <a href="https://www.irs.gov/pub/irs-pdf/fw8bene.pdf">W-8BEN-E</a>)
                    </li>
                    <li>
                      You're claiming income connected with trade or business in the U.S.
                      (use
                      <a href="https://www.irs.gov/pub/irs-pdf/fw8eci.pdf">W-8ECI</a>)
                    </li>
                    <li>
                      You are receiving compensation for personal services performed in
                      the U.S. (use
                      <a href="https://www.irs.gov/pub/irs-pdf/f8233.pdf">8233</a> or
                      <a href="https://www.irs.gov/pub/irs-pdf/fw4.pdf">W-4</a>)
                    </li>
                    <li>
                      You are a person acting as an intermediary (use
                      <a href="https://www.irs.gov/pub/irs-pdf/fw8imy.pdf">W-8IMY</a>)
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <h4 class="title is-5">Identification of Beneficial Owner</h4>
            <div class="columns is-multiline">
              <div class="column is-6">
                <Field
                  :retry="retry"
                  v-bind.sync="w8ben.line1Name"
                  :label="'1 Name of individual who is the beneficial owner'"
                  :placeholder="'Name of individual who is the beneficial owner'"
                  :required="'You must enter a name.'"
                  :func="'name'"
                  :tooltip="`
                Enter your name. The beneficial owner
                of income is generally the person who is required under
                U.S. tax principles to include the payment in gross income
                on a tax return.
              `"
                />
              </div>
              <div class="column is-6">
                <Select
                  :retry="retry"
                  v-bind.sync="w8ben.line2Country"
                  :options="selectCountry"
                  :label="'2 Country of citizenship'"
                  :placeholder="'Select a country'"
                  :required="'You must select a country.'"
                  :tooltip="`
                Enter your country of citizenship. If you are a dual
                citizen, enter the country where you are both a citizen and
                a resident at the time you complete this form.
                If you are not a resident in any country in which you have
                citizenship, enter the country where you were most
                recently a resident.
              `"
                />
              </div>
              <!-- </div> -->
              <!-- <div class="buttons">
          <button 
            class="button is-link" 
            @click="nextStep()"
            :disabled="!step1Valid">
            Continue
          </button>
        </div> -->
              <!-- </div>

      <div class="box" v-show="step == 2"> -->
              <!-- <h4 class="title is-5">Residence Address</h4> -->
              <!-- <div class="columns is-multiline"> -->
              <div class="column is-12">
                <Field
                  :retry="retry"
                  v-bind.sync="w8ben.line3Address"
                  :label="`3 Permanent residence address. Do not use a P.O. box.`"
                  :placeholder="'Permanent residence address'"
                  :required="'You must enter an address.'"
                  :func="'address'"
                  :tooltip="`
                    Your permanent residence address is the
                    address in the country where you claim to be a resident
                    for purposes of that country’s income tax. If you are
                    completing Form W-8BEN to claim a reduced rate of
                    withholding under an income tax treaty, you must
                    determine your residency in the manner required by the
                    treaty.
                  `"
                />
              </div>
              <div class="column is-6">
                <Field
                  :retry="retry"
                  :label="'City or town'"
                  v-bind.sync="w8ben.line3City"
                  :required="'You must enter a city or town.'"
                />
              </div>
              <div class="column is-6">
                <Field
                  :retry="retry"
                  :label="'State or province'"
                  v-bind.sync="w8ben.line3State"
                  :required="'You must enter a state or province.'"
                />
              </div>
              <div class="column is-6">
                <Field
                  :retry="retry"
                  :label="'Postal code'"
                  v-bind.sync="w8ben.line3PostalCode"
                  :required="line3PostalCodeRequired"
                />
              </div>
              <div class="column is-6">
                <Select
                  :retry="retry"
                  v-bind.sync="w8ben.line3Country"
                  :options="selectCountry"
                  :label="'Country of residence'"
                  :placeholder="'Select a country'"
                  :required="'You must select a country.'"
                />
              </div>
              <div class="column is-12">
                <label class="checkbox">
                  <input type="checkbox" v-model="mailingAddressSame" />
                  Mailing address is same as above
                </label>
              </div>
            </div>

            <h4 class="title is-5" v-show="!mailingAddressSame">Mailing Address</h4>
            <div class="columns is-multiline" v-show="!mailingAddressSame">
              <div class="column is-12">
                <Field
                  :retry="retry"
                  :label="'4 Mailing address (if different from above)'"
                  :placeholder="'Mailing address'"
                  v-bind.sync="w8ben.line4Address"
                  :required="false"
                  :func="'address'"
                  :tooltip="`
                    Enter your mailing address only if it is different
                    from the address you show on line 3.
                  `"
                />
              </div>
              <div class="column is-6">
                <Field
                  :retry="retry"
                  :label="'City or town'"
                  v-bind.sync="w8ben.line4City"
                  :required="false"
                />
              </div>
              <div class="column is-6">
                <Field
                  :retry="retry"
                  :label="'State or province'"
                  v-bind.sync="w8ben.line4State"
                  :required="false"
                />
              </div>
              <div class="column is-6">
                <Field
                  :retry="retry"
                  :label="'Postal code'"
                  v-bind.sync="w8ben.line4PostalCode"
                  :required="false"
                />
              </div>
              <div class="column is-6">
                <Select
                  :retry="retry"
                  v-bind.sync="w8ben.line4Country"
                  :options="selectCountry"
                  :label="'Country of mailing address'"
                  :placeholder="'Select a country'"
                  :required="false"
                />
              </div>
            </div>

            <div class="buttons">
              <button class="button is-link" @click="nextStep()" :disabled="!step1Valid">
                Continue
              </button>
            </div>
          </div>

          <div v-show="step == 2">
            <h4 class="title is-5">Tax ID Information</h4>
            <div class="columns is-multiline">
              <div class="column is-6">
                <!-- TODO add a tooltip here -->
                <Field
                  :retry="retry"
                  :label="'5 U.S. taxpayer identification number'"
                  :placeholder="'U.S. taxpayer identification number'"
                  v-bind.sync="w8ben.line5UsTin"
                  :func="'tin'"
                  :path="'/validate'"
                  :meta="{
                    user,
                    iso: 'us',
                    type: 'individual',
                  }"
                  :required="false"
                  :tooltip="`
                    If you have a social security number (SSN) or individual taxpayer 
                    identification number (ITIN), enter it here. To claim
                    certain treaty benefits, you must complete line 5 by
                    submitting an SSN or ITIN, or line 6 by providing a foreign
                    tax identification number (foreign TIN).
                  `"
                />
              </div>
              <div class="column is-6">
                <!-- Per instructions, this should be associated with country on line 3 -->
                <Field
                  :retry="retry"
                  :label="`6 Foreign tax identifying number`"
                  :placeholder="'Foreign tax identification number'"
                  v-bind.sync="w8ben.line6ForeignTin"
                  :func="'tin'"
                  :path="'/validate'"
                  :meta="{
                    user,
                    iso: w8ben.line3Country.value,
                    type: 'individual',
                    unavailable: 'valid',
                  }"
                  :required="false"
                  :tooltip="`
                    You may provide the TIN issued to you by your jurisdiction of 
                    tax residence rather than providing a U.S. TIN on line 5, if required. 
                    If you are not legally required to obtain a TIN in your jurisdiction, 
                    you may enter &quot;not legally required.&quot;
                  `"
                />
              </div>
              <div class="column is-6">
                <Field
                  :retry="retry"
                  :label="`7 Reference number(s)`"
                  :placeholder="'Reference number(s)'"
                  v-bind.sync="w8ben.line7Reference"
                  :required="false"
                  :tooltip="`
                This line may be used by the filer of Form
                W-8BEN or by the withholding agent to whom it is
                provided to include any referencing information that is
                useful to the withholding agent in carrying out its
                obligations.
              `"
                />
              </div>
              <div class="column is-6">
                <Field
                  :retry="retry"
                  :label="`8 Date of birth (MM/DD/YYYY)`"
                  v-bind.sync="w8ben.line8DateOfBirth"
                  :func="'dateOfBirth'"
                  :type="'date'"
                  :required="false"
                  :tooltip="`
                If you are providing this Form W-8BEN to
                document yourself as an account holder with respect to a
                financial account as described above in line 6 that you
                hold with a U.S. office of a financial institution (including a
                U.S. branch of an FFI), provide your date of birth.
              `"
                />
              </div>
            </div>
            <div class="buttons">
              <button class="button is-link" @click="nextStep()" :disabled="!step2Valid">
                Continue
              </button>

              <button class="button is-link is-outlined" @click="previousStep()">
                Go Back
              </button>
            </div>
          </div>

          <div v-show="step == 3">
            <h4 class="title is-5">Claim of Tax Treaty Benefits</h4>
            <div class="columns is-multiline">
              <div class="column is-6">
                <Select
                  :retry="retry"
                  v-bind.sync="w8ben.line9Country"
                  :options="selectCountry"
                  :label="'9 Country of residence for tax treaty purposes'"
                  :placeholder="'Select a country'"
                  :required="false"
                  @change="getTreaty"
                  :tooltip="`
                If you are claiming treaty benefits as a resident of
                a foreign country with which the United States has an
                income tax treaty for payments subject to withholding
                under chapter 3, identify the country where you claim to
                be a resident for income tax treaty purposes.
              `"
                />
              </div>
              <div class="column is-6">
                <Select
                  :retry="retry"
                  v-bind.sync="w8ben.line10Income"
                  :options="selectIncome"
                  :label="'10 Type of Income'"
                  :placeholder="'Select income type'"
                  @change="getTreaty"
                  :required="false"
                  :tooltip="`
                If you're unsure of the type of income to select,
                it's recommended to consult with the requester of the form.
                If you are not claiming treaty benefits, you may leave
                this blank.
              `"
                />
              </div>
            </div>

            <div class="content">
              <p>
                I certify that the beneficial owner is a resident of
                <span class="has-background-link-light has-text-link">{{
                  autofillCountry
                }}</span>
                within the meaning of the income tax treaty between the United States and
                that country.
              </p>
              <p>
                Special rates and conditions (if applicable—see instructions): The
                beneficial owner is claiming the provisions of article and paragraph
                <span class="has-background-link-light has-text-link">{{
                  autofillCitation
                }}</span>
                of the treaty identified on line 9 above to claim a
                <span class="has-background-link-light has-text-link">{{
                  autofillRate
                }}</span
                >% rate of withholding on (specify type of income):
                <span class="has-background-link-light has-text-link">{{
                  autofillIncome
                }}</span>
              </p>
            </div>
            <div class="columns">
              <div class="column is-12">
                <Field
                  :retry="retry"
                  :label="`Additional explanation (optional)`"
                  v-bind.sync="w8ben.line10Explanation"
                  :required="false"
                  :tooltip="`
                In most cases you may leave this blank.
              `"
                />
              </div>
            </div>

            <div class="buttons">
              <button class="button is-link" @click="nextStep()" :disabled="!step3Valid">
                Continue
              </button>

              <button class="button is-link is-outlined" @click="previousStep()">
                Go Back
              </button>
            </div>
          </div>

          <div v-show="step == 4">
            <h5 class="title is-5">Certification</h5>
            <div class="content">
              <p>
                Under penalties of perjury, I declare that I have examined the information
                on this form and to the best of my knowledge and belief it is true,
                correct, and complete. I further certify under penalties of perjury that:
              </p>
              <ul>
                <li>
                  I am the individual that is the beneficial owner (or am authorized to
                  sign for the individual that is the beneficial owner) of all the income
                  to which this form relates or am using this form to document myself for
                  chapter 4 purposes,
                </li>
                <li>The person named on line 1 of this form is not a U.S. person,</li>
                <li>
                  The income to which this form relates is:<br />
                  (a) not effectively connected with the conduct of a trade or business in
                  the United States,<br />
                  (b) effectively connected but is not subject to tax under an applicable
                  income tax treaty, or<br />
                  (c) the partner’s share of a partnership's effectively connected income,
                </li>
                <li>
                  The person named on line 1 of this form is a resident of the treaty
                  country listed on line 9 of the form (if any) within the meaning of the
                  income tax treaty between the United States and that country, and
                </li>
                <li>
                  For broker transactions or barter exchanges, the beneficial owner is an
                  exempt foreign person as defined in the instructions.
                </li>
              </ul>
              <p>
                Furthermore, I authorize this form to be provided to any withholding agent
                that has control, receipt, or custody of the income of which I am the
                beneficial owner or any withholding agent that can disburse or make
                payments of the income of which I am the beneficial owner. I agree that I
                will submit a new form within 30 days if any certification made on this
                form becomes incorrect.
              </p>
            </div>

            <div class="columns is-multiline">
              <div class="column is-12">
                <!-- TODO make a checkbox agreement about electronic signature -->
                <Field
                  :retry="retry"
                  :label="'Sign Here'"
                  :placeholder="'Signature'"
                  v-bind.sync="w8ben.signature"
                  :signature="true"
                  :func="'name'"
                  :required="'Signature is required.'"
                  :tooltip="`
                Form W-8BEN may be signed electronically by the beneficial owner or
                or an individual authorized to sign for beneficial owner.
              `"
                />
                <!-- TODO add consent line? -->
                <!-- <div class="field">
              <div class="control">
                <label class="checkbox">
                  <input type="checkbox" v-model="form.signatureConsent" style="margin-right: 5px;">
                  I agree that my electronic signature is the legal equivalent of my manual signature.
                </label>
              </div>
            </div> -->
              </div>
              <div class="column is-6">
                <Field
                  :retry="retry"
                  :label="`Today's Date`"
                  :type="'date'"
                  :func="'signatureDate'"
                  v-bind.sync="w8ben.signatureDate"
                />
              </div>
              <div class="column is-6">
                <Field
                  :retry="retry"
                  :label="`Capacity in which acting`"
                  v-bind.sync="w8ben.signatureCapacity"
                  :required="false"
                  :tooltip="`
                    If the form is not being signed by the beneficial owner, explain the capacity 
                    in which the signer is acting.
                  `"
                />
              </div>
            </div>

            <div class="buttons">
              <button class="button is-link" @click="nextStep()" :disabled="!step4Valid">
                Continue
              </button>

              <button class="button is-link is-outlined" @click="previousStep()">
                Go Back
              </button>
            </div>
          </div>

          <div v-show="step == 5">
            <div class="columns is-multiline">
              <div class="column is-12">
                <h5 class="title is-5">Send Your Form</h5>

                <div class="notification is-link is-light" v-if="guestUser">
                  You are sending this form as a <strong>guest</strong>. We will send a
                  one-time pin to your email address, and you will need to enter it before
                  your form is delivered. If you would like to send this form as a
                  registered user, please <a @click="modal = 'login'">log in</a> or
                  <a @click="modal = 'signup'">sign up</a>.
                </div>

                <div class="field">
                  <Field
                    :retry="retry"
                    :label="'Your email address'"
                    :placeholder="'Your email address'"
                    v-bind.sync="form.senderEmail"
                    :func="'email'"
                  />
                </div>
              </div>
              <div class="column is-12" v-if="formRequest">
                <h3 class="title is-7 mb-1">
                  This W-8BEN will be electronically delivered to
                </h3>
                <span v-if="formRequest.sender.businessName">
                  {{ formRequest.sender.businessName }}<br />
                </span>
                <span v-else-if="formRequest.sender.name">
                  {{ formRequest.sender.name }}<br />
                </span>
                <a v-else :href="`mailto:${formRequest.sender.email}`">
                  {{ formRequest.sender.email }}
                </a>
                <br />
              </div>
              <div class="column is-12" v-else>
                <Field
                  :retry="retry"
                  :label="'Recipient\'s email address'"
                  :placeholder="'Recipient\'s email address'"
                  v-bind.sync="form.receiverEmail"
                  :func="'email'"
                />
              </div>
            </div>
            <div class="notification is-danger" v-if="error">
              {{ error }}
            </div>
            <div class="buttons">
              <button
                v-if="guestUser"
                class="button is-link"
                @click="sendPin()"
                :class="{ 'is-loading': processing }"
                :disabled="!allValid"
              >
                Continue
              </button>

              <button
                v-else
                class="button is-link"
                @click="submit()"
                :class="{ 'is-loading': processing }"
                :disabled="!allValid"
              >
                Send Form
              </button>

              <button class="button is-link is-outlined" @click="previousStep()">
                Go Back
              </button>
            </div>
          </div>
        </div>

        <div
          class="has-text-centered has-text-grey-light whitelabel-footer"
          v-if="whitelabel"
        >
          Securely processed by
          <a href="https://nextform.app/" target="_blank">Nextform</a> on behalf of
          {{
            formRequest.sender.businessName ||
            formRequest.sender.name ||
            formRequest.sender.email
          }}
        </div>
      </div>
    </section>

    <div class="modal is-active" v-if="modal == 'pin'">
      <div class="modal-background" @click="modal = ''"></div>
      <div class="modal-content" style="max-width: 500px">
        <div class="box">
          <h2 class="title is-4 has-text-centered">One-time PIN</h2>
          <h3 class="subtitle is-6 has-text-centered"></h3>
          <br />

          <p>
            Enter the PIN sent to
            <strong>{{ form.senderEmail.value }}</strong>
          </p>

          <br />

          <Field
            :label="'Six-digit PIN'"
            :placeholder="'000000'"
            v-bind.sync="pin"
            :large="true"
            :green="true"
            :meta="{ email: form.senderEmail.value }"
            :func="'pin'"
            @submit="submit()"
            :path="'/oneTimePin/pin'"
          />

          <div class="buttons">
            <button
              class="button is-link"
              :class="{ 'is-loading': processing }"
              @click="submit()"
              :disabled="!pin.valid"
            >
              Send Form
            </button>
          </div>
          Want to
          <a @click="modal = 'login'">log in</a> or
          <a @click="modal = 'signup'">sign up</a> instead?
        </div>
      </div>
    </div>

    <div class="modal is-active" v-if="modal == 'signup'">
      <div class="modal-background" @click="modal = ''"></div>
      <div class="modal-content" style="max-width: 500px">
        <div class="box">
          <h2 class="title is-4 has-text-centered">Create an Account</h2>
          <h3 class="subtitle is-6 has-text-centered">
            Please create a free account to continue
          </h3>
          <SignupForm @success="setUserAndContinue" />
          <br />
          Want to <a @click="modal = ''">continue as guest</a> or
          <a @click="modal = 'login'">log in</a>?
        </div>
      </div>
    </div>

    <div class="modal is-active" v-if="modal == 'login'">
      <div class="modal-background" @click="modal = ''"></div>
      <div class="modal-content" style="max-width: 500px">
        <div class="box">
          <h2 class="title is-4 has-text-centered">Log In</h2>
          <h3 class="subtitle is-6 has-text-centered">
            Please log in to your account to continue
          </h3>
          <LoginForm @success="setUserAndContinue" />
          <br />
          Want to <a @click="modal = ''">continue as guest</a> or
          <a @click="modal = 'signup'">sign up</a>?
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.whitelabel-footer {
  font-size: 0.75rem;
}
.whitelabel-footer a,
.whitelabel-footer a:visited {
  color: #888;
}
.whitelabel-footer a:hover,
.whitelabel-footer a:active {
  color: #222;
}
/* non-interactive tabs */
.tabs ul li a {
  cursor: default;
}
.tabs ul li a:hover {
  cursor: default;
  border-bottom-color: #dbdbdb;
}
.tabs ul li.is-active a {
  cursor: default;
  border-bottom-color: #3273dc;
}
</style>

<script>
import {
  api,
  origin,
  field,
  select,
  parseError,
  images,
  flattenFields,
} from '@/modules/util'
import selectCountry from '@/modules/selectCountry.json'
import selectIncome from '@/modules/selectIncome.json'
import dayjs from 'dayjs'

export default {
  name: 'SendFormW8',
  props: ['user'],
  data() {
    return {
      origin,
      retry: 0,
      images,
      loading: true,
      processing: false,
      treaty: null,
      pin: field(),
      modal: '',
      error: '',
      step: 1,
      mailingAddressSame: true,
      showInstructions: false,
      line3PostalCodeRequired: false,

      // data for selects
      selectCountry,
      selectIncome,

      // other fields
      formRequest: null,
      token: this.$route.query.token || '',

      form: {
        senderEmail: field(),
        receiverEmail: field(),
      },

      w8ben: {
        line1Name: field(),
        line2Country: select(),
        line3Address: field(),
        line3City: field(),
        line3State: field(),
        line3PostalCode: field(),
        line3Country: select(),
        line4Address: field(),
        line4City: field(),
        line4State: field(),
        line4PostalCode: field(),
        line4Country: select(),
        line5UsTin: field(),
        line6ForeignTin: field(),
        line7Reference: field(),
        line8DateOfBirth: field(),
        line9Country: select(),
        line10Income: select(),
        line10Citation: field(),
        line10Rate: field(),
        line10Explanation: field(),
        signature: field(),
        signatureDate: field({ value: dayjs().format('YYYY-MM-DD') }),
        signatureCapacity: field(),
      },
    }
  },

  watch: {
    'w8ben.line3Address.value': {
      immediate: true,
      handler() {
        if (!/\d/i.test(this.w8ben.line3Address.value)) {
          this.line3PostalCodeRequired = 'Postal code required for this address.'
          if (!this.w8ben.line3PostalCode.value) {
            this.w8ben.line3PostalCode.valid = false
            this.w8ben.line3PostalCode.error = ''
          }
        } else {
          this.line3PostalCodeRequired = false
          this.w8ben.line3PostalCode.valid = true
        }
      },
    },

    '$route.query.step': {
      immediate: true,
      handler() {
        try {
          this.step = parseInt(this.$route.query.step) || 1
        } catch (err) {
          this.step = 1
        }
      },
    },
    user: {
      immediate: true,
      handler() {
        if (this.user && this.user.name && !this.w8ben.line1Name.value) {
          this.w8ben.line1Name.value = this.user.name
          this.w8ben.line1Name.valid = true
        }
        if (this.user && this.user.email && !this.form.senderEmail.value) {
          this.form.senderEmail.value = this.user.email
          this.form.senderEmail.valid = true
        }
      },
    },
    formRequest: {
      immediate: true,
      handler() {
        if (this.formRequest) {
          if (this.formRequest.status == 'complete' && this.formRequest.type != 'link') {
            this.nextStep(10)
          }
          this.form.receiverEmail.value = this.formRequest.sender.email
          this.form.receiverEmail.valid = true
          this.form.senderEmail.value = this.formRequest.receiverEmail
          this.form.senderEmail.valid = Boolean(this.formRequest.receiverEmail)

          // handle autofill
          if (this.formRequest.autofill && this.formRequest.formNumber == 'w8ben') {
            // TODO add some code to translate the autofill from w9
            for (const key in this.formRequest.autofill) {
              const value = this.formRequest.autofill[key]
              if (typeof value == 'string') {
                this.w8ben[key].value = value
              } else if (typeof value == 'object') {
                this.w8ben[key].value = value.value
                this.w8ben[key].disabled = value.disabled
              }
            }
            this.getTreaty()
          }
          this.retry++
        }
      },
    },
  },

  computed: {
    guestUser() {
      return !this.formRequest && !this.user.id
    },

    whitelabel() {
      return this.formRequest && this.$route.query.token
    },

    step1Valid() {
      return (
        this.w8ben.line1Name.valid &&
        this.w8ben.line2Country.valid &&
        this.w8ben.line3Address.valid &&
        this.w8ben.line3City.valid &&
        this.w8ben.line3State.valid &&
        this.w8ben.line3PostalCode.valid &&
        this.w8ben.line3Country.valid &&
        this.w8ben.line4Address.valid &&
        this.w8ben.line4City.valid &&
        this.w8ben.line4State.valid &&
        this.w8ben.line4PostalCode.valid &&
        this.w8ben.line4Country.valid
      )
    },

    step2Valid() {
      return (
        this.w8ben.line5UsTin.valid &&
        this.w8ben.line6ForeignTin.valid &&
        this.w8ben.line7Reference.valid &&
        this.w8ben.line8DateOfBirth.valid
      )
    },

    step3Valid() {
      return (
        this.w8ben.line9Country.valid &&
        this.w8ben.line10Income.valid &&
        this.w8ben.line10Explanation.valid
      )
    },

    step4Valid() {
      return (
        this.w8ben.signature.valid &&
        this.w8ben.signatureDate.valid &&
        this.w8ben.signatureCapacity.valid
      )
    },

    allValid() {
      return (
        this.step1Valid &&
        this.step2Valid &&
        this.step3Valid &&
        this.step4Valid &&
        this.form.senderEmail.valid &&
        this.form.receiverEmail.valid
      )
    },

    autofillCountry() {
      if (this.treaty) return this.treaty.countryName
      return '_______________'
    },
    autofillCitation() {
      if (this.treaty) return this.treaty.citation
      return '_______'
    },
    autofillRate() {
      if (this.treaty) return (this.treaty.rate * 100).toString()
      return '____'
    },
    autofillIncome() {
      if (this.treaty) return this.treaty.incomeName
      return '__________________'
    },
  },

  methods: {
    toggleShowInstructions() {
      this.showInstructions = !this.showInstructions
    },

    nextStep(i) {
      // build the query
      const query = { step: i || this.step + 1 }
      if (this.token) query.token = this.token
      this.$router.push({ query })
    },

    previousStep(i) {
      const query = { step: i || this.step - 1 }
      if (this.token) query.token = this.token
      this.$router.push({ query })
    },

    async init() {
      try {
        this.loading = true
        const token = this.token
        if (!token) throw 'No token.'
        const res = await api.get(`/formRequests/autofill?token=${this.token}`)
        if (!res.data) throw 'No form request.'
        this.formRequest = res.data
        this.loading = false
      } catch (err) {
        this.loading = false
      }
    },

    // async signupOrLogin() {
    //   if (this.user.id || this.formRequest) {
    //     this.modal = ''
    //     this.nextStep(5)
    //   } else this.modal = 'signup'
    // },

    async setUserAndContinue() {
      this.loading = true
      this.$emit('init')
      this.modal = ''
      this.nextStep(5)
      this.loading = false
    },

    // TODO handle more than one form submitted on the same token
    async submit() {
      try {
        this.processing = true

        // flatten fields and add common fields to the form object
        const form = flattenFields(this.form)
        const w8ben = flattenFields(this.w8ben)
        form.name = w8ben.line1Name

        // post the form
        const res = await api.post('/forms/w8ben', {
          form,
          w8ben,
          formRequest: this.formRequest,
          pin: this.pin.value,
        })

        // go to the thank-you URL
        if (this.formRequest && this.formRequest.thankYouUrl) {
          window.top.location.href = this.formRequest.thankYouUrl
        } else {
          this.$router.push({ name: 'Preview', params: { token: res.data.token } })
        }
      } catch (err) {
        this.error = parseError(err)
        this.processing = false
      }
    },

    async sendPin() {
      try {
        this.processing = true
        await api.post('/oneTimePins', { email: this.form.senderEmail.value })
        this.processing = false
        this.modal = 'pin'
      } catch (err) {
        this.error = parseError(err)
        this.processing = false
      }
    },

    async getTreaty() {
      try {
        if (!this.w8ben.line9Country.value || !this.w8ben.line10Income.value) return
        const res = await api.get('/fields/w8ben/treaty', {
          params: {
            iso: this.w8ben.line9Country.value,
            income: this.w8ben.line10Income.value,
          },
        })
        if (!res.data.treaty) throw 'No treaty.'
        this.treaty = res.data.treaty
        this.w8ben.line10Citation.value = this.treaty.citation
        this.w8ben.line10Rate.value = this.treaty.rate
      } catch (err) {
        this.treaty = null
        this.w8ben.line10Citation.value = ''
        this.w8ben.line10Rate.value = ''
      }

      // if (res.data.treaty) {
      //   const treaty = res.data.treaty
      //   this.treaty = treaty /////////////////
      //   this.line10Citation.value = treaty.income.citation
      //   this.line10Rate.value = treaty.income.rate
      // }

      // autofillCountry() {
      //   if (this.treaty) return this.treaty.country
      //   return '_______________'
      // },
      // autofillCitation() {
      //   if (this.treaty) return this.treaty.income.citation
      //   return '_______'
      // },
      // autofillRate() {
      //   if (this.treaty) return (this.treaty.income.rate * 100).toString()
      //   return '____'
      // },
      // autofillIncome() {
      //   if (this.treaty) return this.treaty.income.name
      //   return '__________________'
      // }
    },
  },
  mounted() {
    this.init()
  },
}
</script>
